<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="purchases" v-model:filters="filters1" v-model:selection="selectedRow"
                    selectionMode="multiple" :metaKeySelection="false" :filters="filters1" filterDisplay="menu"
                    dataKey="id" :globalFilterFields="['purchaseno','factoryname']" scrollable :tableStyle="styleHeight"
                    :scrollHeight="sHeight" responsiveLayout="scroll" :loading="loading" :paginator="true" :lazy="true"
                    :rows="pagesize" :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:30%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入采购合同号或工厂简称模糊查询"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink" #body="slotProps">
                            <Button :label="slotProps.data.purchaseno" @click="openPurchase(slotProps.data,false)"
                                class="p-button-link" />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                        <template #filterfooter="{filterCallback}">
                            <Button type="button" label="保存查询方案" @click="filterCallback();saveCustomFilter();"
                                class="p-button-outlined p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading"
                :saveBeforeMethod="saveBefore" :currentRow="currentRow" :childNewDataConfig="childNewDataConfig"
                :closeParentMehod="closeMaximizable" :freshListMethod="freshList" :importAddColsMethod="importAddCols"
                :CellEditCompleteMethod="cellEditComplete" :loadDataAfterMethod="loadDataAfter"
                :importAddQueryConditionMethod="importAddQueryCondition" :childTableFooter="childTableFooter"
                :childDelDataConfig="childDelDataConfig" :initDetailMethod="initDetail" :mainelementCss="mainelementCss"
                :dropOtherConfig="dropOtherConfig" :childExpandePageConfig="childExpandeConfig"
                :pageJsonConfig="pageJsonConfig" :CellEditCompleteExpandeMethod="cellEditCompleteExpande"
                :CellEditInit="productCellEditInit" :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button :label="currentState" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openLeftPosition" />
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="保存" icon="pi pi-check" @click="saveData" :loading="butLoading"
                    autofocus />
                <Button v-if="!readOnly" label="提交" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="流程信息" v-model:visible="displayPosition" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closePosition" autofocus />
        </template>
    </Dialog>
    <Dialog header="新建交货明细" v-model:visible="displayDelivery" :style="{width: '60vw'}" :maximizable="true"
        :modal="true">
        <div class="grid">
            <div class="flex flex-column col-2">
                <label for="ordertime">下单时间</label>
                <Calendar :showTime="false" id="ordertime" v-model="deliveryObj.ordertime" hourFormat="24"
                    dateFormat="yy-mm-dd" />
            </div>
            <div class="flex flex-column col-2">
                <label for="deliverytime">交期</label>
                <Calendar :showTime="false" id="deliverytime" v-model="deliveryObj.deliverytime" hourFormat="24"
                    dateFormat="yy-mm-dd" />
            </div>
            <div class="flex flex-column col-2">
                <label for="containersize">货柜尺寸</label>
                <CalfDropdown :showClear="true" :loadAll="true" v-model:selectedValue="deliveryObj.containersize"
                    v-model:selectedTxt="deliveryObj.containersizename" id="containersize"
                    dictObjName="dictionary^ContainerSize" />
            </div>
        </div>
        <TabView>
            <TabPanel header="产品">
                <Toolbar class="mb-2">
                    <template #start>
                        <Button label="新建" icon="pi pi-plus" class="p-button-success mr-2"
                            @click="createDeliveryProduct()" />
                        <Button label="移除" icon="pi pi-trash" class="p-button-danger mr-2"
                            @click="delDeliveryProduct()" />
                    </template>
                </Toolbar>
                <DataTable :value="deliveryObj.products" v-model:selection="selectedDeliveryProductData" showGridlines
                    :scrollable="true" :scrollHeight="300" stripedRows scrollDirection="both" dataKey="id"
                    editMode="cell" class="editable-cells-table" responsiveLayout="scroll"
                    @row-edit-save="deliveryProductRowEditSave($event,slotProps.data.rows)"
                    @cell-edit-complete="cellDeliveryProductEditComplete($event)">
                    <Column selectionMode="multiple" footerStyle="width:45px;" bodyStyle="width:45px;"
                        headerStyle="width:45px;"></Column>
                    <Column field="mpn" header="MPN" :sortable="false" bodyStyle="width:20%" headerStyle="width:20%">
                        <template #editor="{ data }">
                            <Dropdown v-model="data['productid']" filter :options="deliveryProducts" optionValue="code"
                                optionLabel="name" placeholder="" @change="deliveryProductChange($event,data)" />
                        </template>
                        <template #body="{data}">
                            <label>{{data['mpn']}}</label>
                        </template>
                    </Column>
                    <Column field="sku" header="SKU" :sortable="false" bodyStyle="width:20%" headerStyle="width:20%">
                    </Column>
                    <Column field="productname" header="产品名称" :sortable="false" bodyStyle="width:20%"
                        headerStyle="width:20%">
                    </Column>
                    <Column field="factorysku" header="工厂SKU" :sortable="false" bodyStyle="width:20%"
                        headerStyle="width:20%">
                    </Column>
                    <Column field="quantity" header="数量" :sortable="false" bodyStyle="width:10%"
                        headerStyle="width:10%">
                        <template #editor="{ data }">
                            <InputText v-model="data['quantity']" style="width:100%" autofocus />
                        </template>
                        <template #body="{data}">
                            <label>{{data['quantity']}}</label>
                        </template>
                    </Column>
                </DataTable>
            </TabPanel>
        </TabView>
        <template #footer>
            <Button label="确定" icon="pi pi-check" @click="saveDelivery" autofocus />
        </template>
    </Dialog>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import {
        FilterMatchMode,
    } from 'primevue/api';
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import FlowHistory from '../../../components/FlowHistory.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    import CalfDropdown from '../../../components/CalfDropdown.vue';
    // import purchasePageJson from "@/data/purchaseConfig.json";
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '693';
            const listcolumns = ref();
            const usermoduleauth = [];
            return {
                confirm,
                currentMenuId,
                listcolumns,
                usermoduleauth
            };
        },
        data() {
            return {
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                selectedDeliveryProductData: [],
                deliveryProducts: [],
                deliveryObj: {
                    id: '',
                    parentid: '',
                    ordertime: '',
                    deliverytime: '',
                    containersize: '',
                    containersizename: '',
                    products: [{
                        id: '',
                        parentid: '',
                        productid: '',
                        tblpurchaseid: '',
                        productname: '',
                        mpn: '',
                        sku: '',
                        factorysku: '',
                        quantity: ''
                    }]
                },
                displayDelivery: false,
                butLoading: false,
                recordsubject: '',
                readOnly: false,
                displayMaximizable: false,
                displayQueryPlan: false,
                displayPosition: false,
                position: 'bottomleft',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                flowId2: '',
                startStateId2: '',
                flowId: '',
                startStateId: '',
                currentState: '流程信息-当前环节:采购申请',
                currentRow: {
                    bindpage: 'purchase',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'Purchase',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                currencyDict: [],
                flowhistoryJson: null,
                selectedRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                listTitle: '草稿列表',
                selectedKey: {
                    693: true
                },
                childNewDataConfig: {
                    products: false,
                    files: true,
                    deliverys: true,
                    contractterms: true,
                    deliverysfun: () => {
                        this.createdeliverys();
                    },
                },
                childDelDataConfig: {
                    products: true,
                    files: true,
                    deliverys: true,
                },
                childTableFooter: {
                    products: {
                        mpn: '合计',
                        quantity: 0,
                        allprice: 0,
                        grossweight: 0,
                        netweight: 0,
                    }
                },
                mainelementCss: {
                    deliverytimeexplain: 'field col-12 md:col-4',
                    paymenttype: 'field col-12 md:col-4',
                    shippingdate: 'field col-12 md:col-4'
                },
                dropOtherConfig: {
                    currency: 'exchangerate',
                    currencyfun: (childelements, v_val, v_dictsource) => {
                        this.currencyChange(childelements, v_val, v_dictsource);
                    },
                    factoryidfun: (v_elementName, v_txt, v_value, v_dictsource, allmainelements) => {
                        this.factoryChange(v_elementName, v_txt, v_value, v_dictsource, allmainelements);
                    },
                },
                pageJsonConfig: {},
                pageJsonConfig1: {},
                pageJsonConfig2: {},
                expandedKey: null,
                childExpandeConfig: {},
                childExpandeConfig1: {},
                childExpandeConfig2: {},
                purchases: [],
                loading: true,
                nodes: [],
                items: [{
                    label: '采购操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                            label: '新建采购',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.createPurchase();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '删除',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.delPurchase();
                            }
                        }
                    ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.displayDelivery = false;
            this.init();
        },
        methods: {
            init() {
                this.configService.getpurchase(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.pageJsonConfig1 = data.page;
                    this.pageJsonConfig2 = data.page2;
                    this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                        'childExpandeConfig2'
                    ];
                    this.childExpandeConfig1 = data.page['region_3']['left']['element'][0][
                        'childExpandeConfig2'
                    ];
                    this.childExpandeConfig2 = data.page2['region_3']['left']['element'][0][
                        'childExpandeConfig2'
                    ];
                    this.initFilters1();
                    this.loadFlowInfo2();
                    this.loadMenuTree();
                    CommonJs.loadDictFromCache('Currency', 'dictionaryCurrency', this.currencyDict, this
                        .setloadDictFromCache);
                    this.loadUserAuth();
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            setloadDictFromCache(v_currencyDict) {
                this.currencyDict = v_currencyDict;
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('692', this.currentMenuId, this.$route, this
                    .loadData, this.setlistTitleFun, this.setloadMenuTree);
            },
            loadUserAuth() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '692',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermoduleauthlist(where:" + JSON.stringify(listwhere) +
                    "){appid own authtype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.usermoduleauth = jsonData.data.usermoduleauthlist;

                        this.loadFlowInfo();
                    }
                });
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '草稿列表', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'draftbox',
                        value: '',
                        operation: '='
                    }]
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'purchaseno,factoryname',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{purchaselist(where:" + JSON.stringify(listwhere) +
                    "){id purchaseno factoryname linkmanname signingdate currencyname exchangerate owner ourcompany deliverydate paymenttype shippingdate datatype approvestatusname approvestatus flowid workflowid boxid stateid currentstatename}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.purchases = jsonData.data.purchaselist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            createPurchase() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreatePurchase')) {
                    MessageTip.warnmsg('请联系管理员开通新建采购权限');
                    return;
                }
                this.recordsubject = '新建采购';
                this.childTableFooter = {
                    products: {
                        mpn: '合计',
                        quantity: 0,
                        allprice: 0,
                        grossweight: 0,
                        netweight: 0
                    }
                };
                this.currentRow = {
                    bindpage: 'purchase',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'Purchase',
                    boxid: '',
                    stateid: this.startStateId,
                    flowid: this.flowId,
                };
                this.childExpandeConfig = this.childExpandeConfig1;
                this.pageJsonConfig = this.pageJsonConfig1;
                this.displayMaximizable = true;
            },
            openPurchase(v_data, v_isCopy) {
                if (v_isCopy) {
                    if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreatePurchase')) {
                        MessageTip.warnmsg('请联系管理员开通新建采购权限');
                        return;
                    }
                    this.currentState = '流程信息';
                    this.recordsubject = '复制采购单' + v_data.purchaseno;
                } else {
                    this.currentState = '流程信息-当前环节:' + v_data.currentstatename;
                    this.recordsubject = '采购单' + v_data.purchaseno;
                }
                this.childTableFooter = {
                    products: {
                        mpn: '合计',
                        quantity: 0,
                        allprice: 0,
                        grossweight: 0,
                        netweight: 0
                    }
                };
                if (v_data['datatype'] == '1') {
                    this.childNewDataConfig = {
                        products: false,
                        files: true,
                        deliverys: true,
                        contractterms: true,
                        deliverysfun: () => {
                            this.createdeliverys();
                        },
                    };
                    this.childDelDataConfig = {
                        products: true,
                        files: true,
                        deliverys: true,
                    };
                    this.currentRow = {
                        isCopy: v_isCopy,
                        bindpage: 'purchase',
                        id: v_data.id,
                        workflowid: v_data.workflowid,
                        flowguid: 'Purchase',
                        boxid: v_data.boxid,
                        stateid: this.startStateId,
                        flowid: this.flowId,
                    };
                    this.childExpandeConfig = this.childExpandeConfig1;
                    this.pageJsonConfig = this.pageJsonConfig1;
                } else {
                    this.childNewDataConfig = {
                        products: false,
                        deliverys: false,
                    };
                    this.childDelDataConfig = {
                        products: true,
                        deliverys: true,
                    };
                    this.recordsubject = '采购单修改申请' + v_data.purchaseno;
                    this.currentRow = {
                        isCopy: false,
                        bindpage: 'purchaseedit',
                        id: v_data.id,
                        workflowid: v_data.workflowid,
                        flowguid: 'PurchaseEdit',
                        boxid: v_data.boxid,
                        stateid: this.startStateId2,
                        flowid: this.flowId2,
                    };
                    this.childExpandeConfig = this.childExpandeConfig2;
                    this.pageJsonConfig = this.pageJsonConfig2;
                }
                this.displayMaximizable = true;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            delPurchase() {
                this.$confirm.require({
                    message: '确认要删除?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        if (!this.selectedRow || this.selectedRow.length == 0) {
                            MessageTip.warnmsg('请选择要删除采购单');
                            return;
                        }
                        var purchaseIds = [];
                        var purchaseIds2 = [];
                        for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                            if (this.selectedRow[k1]["datatype"] == '1') {
                                purchaseIds.push({
                                    "id": this.selectedRow[k1]["id"]
                                });
                            } else {
                                purchaseIds2.push({
                                    "id": this.selectedRow[k1]["id"]
                                });
                            }
                        }
                        if (purchaseIds.length > 0) {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                                "delete{purchaselist(o:" + JSON.stringify(purchaseIds) +
                                "){id errmsg}}"
                            ).then(res => {
                                if (res.errcode == "0") {
                                    this.selectedRow = null;
                                    this.loadData(1);
                                    MessageTip.successmsg('删除采购单成功');
                                } else {
                                    MessageTip.warnmsg('删除采购单失败');
                                }
                            });
                        }
                        if (purchaseIds2.length > 0) {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                                "delete{purchaseeditlist(o:" + JSON.stringify(purchaseIds2) +
                                "){id errmsg}}"
                            ).then(res => {
                                if (res.errcode == "0") {
                                    this.selectedRow = null;
                                    this.loadData(1);
                                    MessageTip.successmsg('删除采购修改申请单成功');
                                } else {
                                    MessageTip.warnmsg('删除采购修改申请单失败');
                                }
                            });
                        }
                    },
                    reject: () => {}
                });
            },
            copyPurchase() {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要复制的采购单');
                    return;
                }
                if (this.selectedRow.length != 1) {
                    MessageTip.warnmsg('只能复制一个采购单');
                    return;
                }
                this.selectedRow[0]['workflowid'] = '-1';
                this.selectedRow[0]['boxid'] = '-1';
                this.selectedRow[0]['stateid'] = '-1';
                this.openPurchase(this.selectedRow[0], true);
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            saveBefore(v_data) {
                console.log('saveBefore:');
                console.log(v_data);
                v_data['approvestatus'] = '0';
                v_data['appresult'] = {};
                v_data['shyj'] = {};
                if (v_data['products']) {
                    for (var i = 0; i < v_data['products'].length; i++) {
                        v_data['products'][i]['inspection'] = '0';
                        v_data['products'][i]['inspectionexplain'] = '';
                        v_data['products'][i]['inspectionfile'] = [];
                    }
                }
                return v_data;
            },
            submitData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo();
            },
            saveData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo(true);
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                console.log('recordsubject:' + this.recordsubject);
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            productCellEditInit(event, tablename, colconfigs) {
                if (tablename == 'products') {
                    var countindex = 0;
                    for (var i = 0; i < colconfigs.length; i++) {
                        if (colconfigs[i]['field'] == 'unitprice') {
                            if (event.data['producttype'] && event.data['producttype'] == '2') {
                                colconfigs[i]['type'] = 'LabelString';
                            } else {
                                colconfigs[i]['type'] = 'Decimal';
                            }
                            countindex = countindex + 1;
                        }
                        if (colconfigs[i]['field'] == 'grossweight') {
                            if (event.data['producttype'] && event.data['producttype'] == '2') {
                                colconfigs[i]['type'] = 'LabelString';
                            } else {
                                colconfigs[i]['type'] = 'Decimal';
                            }
                            countindex = countindex + 1;
                        }
                        if (colconfigs[i]['field'] == 'netweight') {
                            if (event.data['producttype'] && event.data['producttype'] == '2') {
                                colconfigs[i]['type'] = 'LabelString';
                            } else {
                                colconfigs[i]['type'] = 'Decimal';
                            }
                            countindex = countindex + 1;
                        }
                        if (countindex >= 3) {
                            break;
                        }
                    }
                }
            },
            cellEditCompleteExpande(tablename, field, currentrow, parentRow, allcurrentdata, allparentdata,
                childTableFooterObj) {
                console.log('cellEditCompleteExpandeMethod..');
                console.log(tablename);
                console.log(field);
                console.log(currentrow);
                console.log(parentRow);
                console.log(allcurrentdata);
                console.log(allparentdata);
                console.log(childTableFooterObj);
                var price = 0;
                var allprice = 0;
                var grossWeight = 0;
                var netWeight = 0;
                if (!isNaN(Number(parentRow['quantity'])) && !isNaN(Number(currentrow['matching']))) {
                    currentrow['quantity'] = Number(currentrow['matching']) * Number(parentRow[
                        'quantity']);
                }
                if (!isNaN(Number(parentRow['quantity'])) && !isNaN(Number(currentrow['unitprice'])) && !isNaN(Number(
                        currentrow['matching']))) {
                    currentrow['allprice'] = Number(currentrow['matching']) * Number(currentrow[
                        'unitprice']) * Number(parentRow['quantity']);
                }
                for (var i = 0; i < allcurrentdata.length; i++) {
                    if (allcurrentdata[i]['id'] == currentrow['id']) {
                        allcurrentdata[i]['quantity'] = currentrow['quantity'];
                        allcurrentdata[i]['allprice'] = currentrow['allprice'];
                    }
                    if (!isNaN(Number(allcurrentdata[i]['unitprice'])) && !isNaN(Number(allcurrentdata[i][
                            'matching'
                        ]))) {
                        price = price + Number(allcurrentdata[i]['unitprice']) * Number(allcurrentdata[i]['matching']);
                    }
                    if (!isNaN(Number(allcurrentdata[i]['allprice']))) {
                        allprice = allprice + Number(allcurrentdata[i]['allprice']);
                    }
                    if (!isNaN(Number(allcurrentdata[i]['grossweight'])) && !isNaN(Number(allcurrentdata[i][
                            'matching'
                        ]))) {
                        grossWeight = grossWeight + Number(allcurrentdata[i]['grossweight']) * Number(allcurrentdata[i][
                            'matching'
                        ]);
                    }
                    if (!isNaN(Number(allcurrentdata[i]['netweight'])) && !isNaN(Number(allcurrentdata[i][
                            'matching'
                        ]))) {
                        netWeight = netWeight + Number(allcurrentdata[i]['netweight']) * Number(allcurrentdata[i][
                            'matching'
                        ]);
                    }
                }
                parentRow['grossweight'] = grossWeight;
                parentRow['netweight'] = netWeight;
                parentRow['unitprice'] = price;
                parentRow['allprice'] = allprice;
                price = 0;
                allprice = 0;
                grossWeight = 0;
                netWeight = 0;
                for (var i2 = 0; i2 < allparentdata.length; i2++) {
                    if (allparentdata[i2]['id'] == parentRow['id']) {
                        allparentdata[i2]['grossweight'] = parentRow['grossweight'];
                        allparentdata[i2]['netweight'] = parentRow['netweight'];
                        allparentdata[i2]['unitprice'] = parentRow['unitprice'];
                        allparentdata[i2]['allprice'] = parentRow['allprice'];
                    }
                    if (!isNaN(Number(allparentdata[i2]['allprice']))) {
                        allprice = allprice + Number(allparentdata[i2]['allprice']);
                    }
                    if (!isNaN(Number(allparentdata[i2]['grossweight']))) {
                        grossWeight = grossWeight + Number(allparentdata[i2]['grossweight']);
                    }
                    if (!isNaN(Number(allparentdata[i2]['netweight']))) {
                        netWeight = netWeight + Number(allparentdata[i2]['netweight']);
                    }
                }
                //childTableFooterObj['products']['netweight'] = netWeight;
                //childTableFooterObj['products']['grossweight'] = grossWeight;
                //childTableFooterObj['products']['allprice'] = allprice;
            },
            cellEditComplete(tablename, field, data, rowdata, childTableFooterObj, v_childelements,
                v_alonechildelements, v_elements) {
                console.log('cellEditCompleteMethod..');
                console.log(field);
                console.log(data);
                console.log(rowdata);
                console.log(childTableFooterObj);
                console.log(v_childelements);
                console.log(v_alonechildelements);
                console.log(v_elements);
                if (tablename == 'products' && field == '') {
                    for (var k6 = 0; k6 < v_elements.length; k6++) {
                        if (v_elements[k6]['name'] == 'currency') {
                            v_elements[k6]['val'] = rowdata['currency'];
                            this.$refs.universalpage.$refs.currency.freshSelect(rowdata['currency']);
                        }
                    }
                }
                if (tablename == 'products' && field == '' && rowdata['parts']) {
                    var unitpricenum2 = 0;
                    var netweight2 = 0;
                    var grossweight2 = 0;
                    for (var i2 = 0; i2 < rowdata['parts'].length; i2++) {
                        var matchingnum3 = Number(rowdata['parts'][i2]['matching']);
                        var unitpricenum3 = Number(rowdata['parts'][i2]['unitprice']);
                        var netweight3 = Number(rowdata['parts'][i2]['netweight']);
                        var grossweight3 = Number(rowdata['parts'][i2]['grossweight']);
                        if (!isNaN(unitpricenum3) && !isNaN(matchingnum3)) {
                            unitpricenum2 = unitpricenum2 + unitpricenum3 * matchingnum3;
                        }
                        if (!isNaN(netweight3) && !isNaN(matchingnum3)) {
                            netweight2 = netweight2 + netweight3 * matchingnum3;
                        }
                        if (!isNaN(grossweight3) && !isNaN(matchingnum3)) {
                            grossweight2 = grossweight2 + grossweight3 * matchingnum3;
                        }
                    }
                    if (rowdata['parts'].length > 0) {
                        rowdata['unitprice'] = unitpricenum2;
                        rowdata['netweight'] = netweight2;
                        rowdata['grossweight'] = grossweight2;
                    }
                }
                if (tablename == 'products' && field == 'quantity' || field == 'unitprice') {
                    var quantitynum = Number(rowdata['quantity']);
                    var unitpricenum = Number(rowdata['unitprice']);
                    if (!isNaN(quantitynum) && !isNaN(unitpricenum)) {
                        rowdata['allprice'] = quantitynum * unitpricenum;
                    }
                    if (field == 'quantity' && !isNaN(quantitynum) && rowdata['parts']) {
                        for (var i3 = 0; i3 < rowdata['parts'].length; i3++) {
                            var matchingnum4 = Number(rowdata['parts'][i3]['matching']);
                            var unitpricenum4 = Number(rowdata['parts'][i3]['unitprice']);
                            if (!isNaN(matchingnum4)) {
                                rowdata['parts'][i3]['quantity'] = matchingnum4 * quantitynum;
                            }
                            var quantitynum4 = Number(rowdata['parts'][i3]['quantity']);
                            if (!isNaN(quantitynum4) && !isNaN(unitpricenum4)) {
                                rowdata['parts'][i3]['allprice'] = unitpricenum4 * quantitynum4;
                            }
                        }
                    }
                    var summary = 0;
                    for (var i = 0; i < data.length; i++) {
                        var tempnum = Number(data[i]['allprice']);
                        if (!isNaN(tempnum)) {
                            summary = summary + tempnum;
                        }
                    }
                    console.log('cellEditCompleteMethod..' + summary);
                    childTableFooterObj['products']['allprice'] = summary;
                }
            },
            initDetail(v_editorMenu) {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'id',
                        value: 'PurchaseTerm',
                        operation: '=',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{generaldictsetlist(where:" + JSON.stringify(listwhere) +
                    "){name categories data {rowid name colname colvalue coltype}}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        var retData = jsonData.data.generaldictsetlist;
                        for (var i = 0; i < retData.length; i++) {
                            var rowObj = {
                                name: retData[i]['name'],
                                categories: retData[i]['categories'],
                                rows: [],
                                cols: [],
                            };
                            for (var j = 0; j < retData[i]['data'].length; j++) {
                                var isAdd = true;
                                for (var k1 = 0; k1 < rowObj.cols.length; k1++) {
                                    if (rowObj.cols[k1]['name'] == retData[i]['data'][j]['colname']) {
                                        isAdd = false;
                                        break;
                                    }
                                }
                                if (isAdd) {
                                    rowObj.cols.push({
                                        label: retData[i]['data'][j]['name'],
                                        name: retData[i]['data'][j]['colname'],
                                        type: retData[i]['data'][j]['coltype']
                                    });
                                }
                            }
                            for (var j1 = 0; j1 < retData[i]['data'].length; j1++) {
                                var rowAdd = true;
                                for (var k2 = 0; k2 < rowObj.rows.length; k2++) {
                                    if (retData[i]['data'][j1]['rowid'] == rowObj.rows[k2]['id']) {
                                        rowAdd = false;
                                        break;
                                    }
                                }
                                if (rowAdd) {
                                    var rowData = {
                                        id: retData[i]['data'][j1]['rowid']
                                    };
                                    for (var k3 = 0; k3 < retData[i]['data'].length; k3++) {
                                        if (retData[i]['data'][j1]['rowid'] == retData[i]['data'][k3][
                                                'rowid'
                                            ]) {
                                            rowData[retData[i]['data'][k3]['colname']] = retData[i]['data'][k3][
                                                'colvalue'
                                            ];
                                        }
                                    }
                                    rowObj.rows.push(rowData);
                                }
                            }
                            for (var k = 0; k < rowObj.rows.length; k++) {
                                v_editorMenu.push({
                                    label: rowObj.rows[k]['SName'],
                                    icon: 'pi pi-plus',
                                    colname: 'termcontent',
                                    content: rowObj.rows[k]['AdditionalInfo'],
                                    command: ($event) => {
                                        this.$refs.universalpage.setEditorValue($event);
                                    }
                                });
                            }
                        }
                    }
                });
            },
            importAddQueryCondition(v_listwhere, v_moduleName) {
                if (v_moduleName == 'product') {
                    v_listwhere.conditions.push({
                        name: 'status',
                        value: '0',
                        operation: '=',
                    });
                    return v_listwhere;
                }
                if (v_moduleName == 'quotationproduct') {
                    var mainDataObj = this.$refs.universalpage.getNoShowElements();
                    console.log('importAddQueryCondition...');
                    console.log(mainDataObj);
                    if (!mainDataObj['factoryid'] || mainDataObj['factoryid'] == '' || mainDataObj['factoryid'] ==
                        '-1') {
                        MessageTip.warnmsg('请先选择工厂');
                        v_listwhere.conditions.push({
                            name: 'err',
                            value: '',
                            operation: '',
                        });
                    } else {
                        v_listwhere.conditions.push({
                            name: 'factoryid',
                            value: mainDataObj['factoryid'],
                            operation: '=',
                        });
                    }
                    v_listwhere.conditions.push({
                        name: 'importdata',
                        value: '',
                        operation: '=',
                    });
                    return v_listwhere;
                }
            },
            loadFlowInfo() {
                requests.plat_requests.post(
                    '/calf/plat/FlowInfo?appkey=' + sessionStorage.getItem(
                        "appkey"), {
                        FlowGuid: 'Purchase',
                        BoxId: '',
                    }).then(res => {
                    console.log('loadFlowInfo...');
                    console.log(res);
                    if (res.errcode == "0") {
                        var retObj = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log(retObj);
                        this.flowId = retObj.FlowId;
                        this.startStateId = retObj.CurrentStateId;

                        var copyid = this.$route.query['copyid'];
                        var copyno = this.$route.query['copyno'];
                        console.log('copyid..');
                        console.log(copyid);
                        console.log('copyno..');
                        console.log(copyno);
                        if (copyid && copyno) {
                            var data = {
                                'purchaseno': copyno,
                                'id': copyid,
                                'workflowid': -1,
                                'boxid': -1,
                            };
                            this.openPurchase(data, true);
                        }
                        var createpurchaseflag = this.$route.query['createpurchase'];
                        if (createpurchaseflag) {
                            this.createPurchase();
                        }
                    }
                });
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData, v_noshowelements,
                v_childExpandeConfig) {
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                console.log(v_noshowelements);
                if (this.currentRow.isCopy) {
                    for (var k3 = 0; k3 < v_mainelements.length; k3++) {
                        if (v_mainelements[k3]['name'] == 'purchaseno') {
                            v_mainelements[k3]['val'] = "*****";
                            break;
                        }
                    }
                }
                if (this.currentRow && this.currentRow['id'] == '-1') {
                    for (var k2 = 0; k2 < v_childelements.length; k2++) {
                        if (v_childelements[k2]['name'] == 'products') {
                            v_childelements[k2]['rows'] = [];
                        }
                        if (v_childelements[k2]['name'] == 'deliverys') {
                            v_childelements[k2]['rows'] = [];
                        }
                        if (v_childelements[k2]['name'] == 'files') {
                            v_childelements[k2]['rows'] = [];
                        }
                    }
                } else {
                    for (var k = 0; k < v_mainelements.length; k++) {
                        if (v_mainelements[k]['name'] == 'currency') {
                            this.currencyChange(v_childelements, v_mainelements[k]['val'], this.currencyDict,
                                v_childExpandeConfig);
                        }
                    }
                    setTimeout(() => {
                        this.$refs.universalpage.setAllExpandedRowsData('deliverys');
                    }, 20);
                }
            },
            factoryChange(v_elementName, v_txt, v_value, v_dictsource, allmainelements) {
                console.log('factoryChange....');
                console.log(v_elementName);
                console.log(v_txt);
                console.log(v_value);
                console.log(v_dictsource);
                console.log(allmainelements);
                var paymentType = '';
                for (var i = 0; i < v_dictsource.length; i++) {
                    if (v_dictsource[i]['code'] == v_value) {
                        if (v_dictsource[i]['ename'] != '0')
                            paymentType = v_dictsource[i]['ename'];
                        break;
                    }
                }
                for (var i2 = 0; i2 < allmainelements.length; i2++) {
                    if (allmainelements[i2]['name'] == 'paymenttype') {
                        allmainelements[i2]['val'] = paymentType;
                        break;
                    }
                }
            },
            currencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
                var ename = '';
                for (var i3 = 0; i3 < v_dictsource.length; i3++) {
                    if (v_dictsource[i3].code == v_val) {
                        ename = v_dictsource[i3].ename;
                        break;
                    }
                }
                var loopcnt = 0;
                var isbreak = false;
                for (var i = 0; i < v_childelements.length; i++) {
                    for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                        if (v_childelements[i].columns[i2].field == 'unitprice') {
                            v_childelements[i].columns[i2].header = '单价(' + ename + ')';
                            loopcnt++;
                        }
                        if (v_childelements[i].columns[i2].field == 'allprice') {
                            v_childelements[i].columns[i2].header = '总价(' + ename + ')';
                            loopcnt++;
                        }
                        if (loopcnt == 2) {
                            isbreak = true;
                            break;
                        }
                    }
                    if (isbreak) {
                        break;
                    }
                }
                if (v_childExpandeConfig && v_childExpandeConfig['products']) {
                    for (var i4 = 0; i4 < v_childExpandeConfig.products.config2[0].columns.length; i4++) {
                        if (v_childExpandeConfig.products.config2[0].columns[i4].field == 'unitprice') {
                            v_childExpandeConfig.products.config2[0].columns[i4].header = '单价(' + ename + ')';
                        }
                        if (v_childExpandeConfig.products.config2[0].columns[i4].field == 'allprice') {
                            v_childExpandeConfig.products.config2[0].columns[i4].header = '总价(' + ename + ')';
                        }
                    }
                } else {
                    if (this.childExpandeConfig['products']) {
                        for (var i5 = 0; i5 < this.childExpandeConfig.products.config2[0].columns.length; i5++) {
                            if (this.childExpandeConfig.products.config2[0].columns[i5].field == 'unitprice') {
                                this.childExpandeConfig.products.config2[0].columns[i5].header = '单价(' + ename + ')';
                            }
                            if (this.childExpandeConfig.products.config2[0].columns[i5].field == 'allprice') {
                                this.childExpandeConfig.products.config2[0].columns[i5].header = '总价(' + ename + ')';
                            }
                        }
                        this.$refs.universalpage.setchildExpandeConfig(this.childExpandeConfig);
                    }
                }
            },
            importAddCols(v_cols, v_graphqlFeild) {
                console.log('importAddCols:1');
                console.log(v_cols);
                v_graphqlFeild = v_graphqlFeild + ' currency exchangerate';
                return v_graphqlFeild;
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            },
            createdeliverys() {
                console.log('createdeliverys....');
                this.deliveryProducts = [];
                var allchildelements = this.$refs.universalpage.getAllChildElements();
                for (var i = 0; i < allchildelements.length; i++) {
                    if (allchildelements[i]['name'] == 'products') {
                        for (var i2 = 0; i2 < allchildelements[i]['rows'].length; i2++) {
                            this.deliveryProducts.push({
                                name: allchildelements[i]['rows'][i2]['mpn'],
                                code: allchildelements[i]['rows'][i2]['productid'],
                                productname: allchildelements[i]['rows'][i2]['productname'],
                                sku: allchildelements[i]['rows'][i2]['sku'],
                                factorysku: allchildelements[i]['rows'][i2]['factorysku'],
                            });
                        }
                        break;
                    }
                }
                var signingdate = '';
                var allmainelements = this.$refs.universalpage.getAllMainElements();
                for (var i3 = 0; i3 < allmainelements.length; i3++) {
                    if (allmainelements[i3]['name'] == 'signingdate') {
                        signingdate = allmainelements[i3]['val'];
                        break;
                    }
                }
                console.log(this.deliveryProducts);
                console.log('createdeliverys....2');
                this.deliveryObj = {
                    id: this.$refs.universalpage.getNewId(),
                    parentid: '',
                    ordertime: signingdate,
                    deliverytime: '',
                    containersize: '',
                    containersizename: '',
                    products: []
                };
                console.log(this.deliveryObj);
                this.displayDelivery = true;
            },
            createDeliveryProduct() {
                this.deliveryObj['products'].push({
                    id: this.$refs.universalpage.getNewId(),
                    parentid: '',
                    productid: '',
                    tblpurchaseid: '',
                    productname: '',
                    mpn: '',
                    sku: '',
                    factorysku: '',
                    quantity: ''
                });
            },
            delDeliveryProduct() {
                console.log(this.selectedDeliveryProductData);
                if (!this.selectedDeliveryProductData || this.selectedDeliveryProductData.length == 0) {
                    MessageTip.warnmsg('请选择移除的数据');
                }
                for (var k2 = 0; k2 < this.selectedDeliveryProductData.length; k2++) {
                    for (var i = 0; i < this.deliveryObj['products'].length; i++) {
                        if (parseInt(this.deliveryObj['products'][i]["id"]) == parseInt(this
                                .selectedDeliveryProductData[k2]["id"])) {
                            this.deliveryObj['products'].splice(i, 1);
                            break;
                        }
                    }
                }
                this.selectedDeliveryProductData = null;
            },
            deliveryProductRowEditSave(event, v_data) {
                console.log(event);
                console.log(v_data);
                let {
                    newData,
                    index
                } = event;
                v_data[index] = newData;
            },
            cellDeliveryProductEditComplete(event) {
                console.log("event:");
                console.log(event);
                for (var pro in event.newData) {
                    event.data[pro] = event.newData[pro];
                }
            },
            saveDelivery() {
                var allchildelements = this.$refs.universalpage.getAllChildElements();
                for (var i = 0; i < allchildelements.length; i++) {
                    if (allchildelements[i]['name'] == 'deliverys') {
                        allchildelements[i]['rows'].push(this.deliveryObj);
                        break;
                    }
                }
                setTimeout(() => {
                    this.$refs.universalpage.setAllExpandedRowsData('deliverys');
                }, 20);
                this.displayDelivery = false;
            },
            deliveryProductChange(v_event, v_row) {
                console.log('deliveryProductChange...');
                console.log(v_event);
                for (var i = 0; i < this.deliveryProducts.length; i++) {
                    if (this.deliveryProducts[i]['code'] == v_event.value) {
                        v_row['productname'] = this.deliveryProducts[i]['productname'];
                        v_row['mpn'] = this.deliveryProducts[i]['name'];
                        v_row['sku'] = this.deliveryProducts[i]['sku'];
                        v_row['factorysku'] = this.deliveryProducts[i]['factorysku'];
                        break;
                    }
                }
                console.log(v_row);
            },
            loadFlowInfo2() {
                requests.plat_requests.post(
                    '/calf/plat/FlowInfo?appkey=' + sessionStorage.getItem(
                        "appkey"), {
                        FlowGuid: 'PurchaseEdit',
                        BoxId: '',
                    }).then(res => {
                    console.log('loadFlowInfo...');
                    console.log(res);
                    if (res.errcode == "0") {
                        var retObj = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log(retObj);
                        this.flowId2 = retObj.FlowId;
                        this.startStateId2 = retObj.CurrentStateId;
                    }
                });
            },
        },
        components: {
            NewUniversalPage,
            FlowHistory,
            'AppFooter': AppFooter,
            CalfDropdown
        }
    }
</script>